// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$black: #120B04;
$yellow: #F5F28D;

.events-page__content {
  padding-top: 50px;
}

.event-header {
  position: fixed;
  top: 50px;
  left: 225px;
  right: 0;
  z-index: 3;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  h3 {
    display: inline-block;
    margin: 0 20px 0 0;
  }
  .event-header__name {

  }
  .event-header__admin {

  }
  &__notes {
    display: flex;
    align-items: center;
    .event-header__notes__status {
      display: inline-block;
      margin: 0 20px 0 0;
    }
  }
}

li {
  ul.sub-menu {
    list-style-type: none;
    margin-left: 30px;
    li {
     // padding: 10px;
      color: white;
      position: relative;
      &:hover {
      }
      a {
        padding-left: 7px;
        margin-left: 5px;
        &:before {
          content: "-";
          position: absolute;
          left: -7px;

          margin-top: -2px;
        }
      }
    }
  }
}
.well .btn, .btn.btn-primary {
  border-radius: 0;
  color: $black;
  background: $yellow;
  border: 1px solid $black;
  transition: all ease-in-out 0.3s;
  &:hover {
    background: darken($yellow, 10%);
  }
}

.mb-3 {
  margin-bottom: 1em;
}

.btn {
  border-radius: 0;
  border: 1px solid $black;
  transition: all ease-in-out 0.3s;
}
a.btn {
  text-decoration: none !important;
}
.btn-group {
  .btn {
    margin-right: 4px;
    &:last-of-type {
    }
  }
}
.form-control {
  border: 1px solid $black;
  border-radius: 0;
}
p.task_rule {
	margin-bottom: 10px;
	span {
		opacity: 0;
		transition: all ease-in-out 0.3s;
	}
	&:hover {
		span {
			opacity: 1;
		}
	}
}